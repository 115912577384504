<template>
  <cover>
    <template #background>
      <img src="https://via.placeholder.com/1920x440?text=adomicilio cover" alt="Adomicilio">
    </template>
    <h1>Promociones</h1>
  </cover>
  <div class="grid-columns-3 container-padding mb-1 mt-2">
    <div class="horizontal card align-start">
      <div class="card-content">
        <div class="img">
          <img src="https://via.placeholder.com/200x200?text=adomicilio"
               alt="A domicilio"/>
          <button>Ver más</button>
        </div>
        <div class="info">
          <button class="btn-clean btn-icon">
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.5 0C12.76 0 11.09 0.81 10 2.09C8.91 0.81 7.24 0 5.5 0C2.42 0 0 2.42 0 5.5C0 9.28 3.4 12.36 8.55 17.04L10 18.35L11.45 17.03C16.6 12.36 20 9.28 20 5.5C20 2.42 17.58 0 14.5 0ZM10.1 15.55L10 15.65L9.9 15.55C5.14 11.24 2 8.39 2 5.5C2 3.5 3.5 2 5.5 2C7.04 2 8.54 2.99 9.07 4.36H10.94C11.46 2.99 12.96 2 14.5 2C16.5 2 18 3.5 18 5.5C18 8.39 14.86 11.24 10.1 15.55Z"
                fill="white"/>
            </svg>
          </button>
          <p class="label color-secondary mb-1">Combo Heineken</p>
          <p class="alt">1 chicharrón de queso + 6 pastores + 4 tacos rib eyPe + 2 Heineken Latón 473 ml </p>
          <h4 class="mb-05 mt-05 color-primary">$440.00</h4>
          <div class="last flex align-center">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22 11L19.56 8.21L19.9 4.52L16.29 3.7L14.4 0.5L11 1.96L7.6 0.5L5.71 3.69L2.1 4.5L2.44 8.2L0 11L2.44 13.79L2.1 17.49L5.71 18.31L7.6 21.5L11 20.03L14.4 21.49L16.29 18.3L19.9 17.48L19.56 13.79L22 11ZM9.09 15.72L5.29 11.91L6.77 10.43L9.09 12.76L14.94 6.89L16.42 8.37L9.09 15.72Z" fill="#F36E44"/>
            </svg>
            <p class="bg-label mt-0 ml-05">Combo</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center mb-5">
    <i class="color-primary pi pi-spin pi-spinner" style="font-size: 2.5rem"></i>
  </div>
</template>
<script>
import Cover from '@/components/Cover'

export default {
  components: {
    Cover
  }
}
</script>
